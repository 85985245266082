.PaginationStatus {
    min-height: 64px;

    & p[role='status'] {
        @mixin p30;
        text-align: center;
        margin-bottom: 8px;
    }
}

.StatusButton {
    &.StatusButton {
        @mixin linkButton;
        margin: auto;
    }
}

.DownArrow {
    & svg {
        transform: rotate(90deg);
    }
}
