@define-mixin ImageAspectRatio $ratio {
    & .PosterImageOverlay {
        @mixin aspectRatio $ratio;
    }
}

@define-mixin IgnoreChildrenFrom $index {
    &:nth-child(n + $index) {
        display: none;
    }
}

@define-mixin oneLeftAndTwoOnTwoRightGridLayout {
    @mixin grid 0, 32px;
    grid-template-columns: repeat(2, 1fr);

    @media (--tablet) {
        grid-template-columns: repeat(4, 1fr);
    }

    & .Poster:first-child {
        @mixin columnsSpan 1, 2;

        @media (--tablet) {
            @mixin columnsSpan 1, 2;
            @mixin rowsSpan 1, 2;
        }
    }

    &.Variant--reversed {
        & .Poster:first-child {
            @media (--tablet) {
                @mixin columnsSpan 3, 2;
            }
        }
    }
}

@define-mixin oneLeftAndFourOnOneRightGridLayout {
    display: block;

    @media (--tablet) {
        @mixin grid 0, 32px;
        grid-template-columns: repeat(4, 1fr);

        & .Poster {
            &:first-child {
                @mixin columnsSpan 1, 3;
                @mixin rowsSpan 1, 6;
            }

            &:not(:first-child) {
                grid-column: 4;
            }
        }

        &.Variant--reversed {
            & .Poster {
                &:first-child {
                    @mixin columnsSpan 2, 4;
                }

                &:not(:first-child) {
                    grid-column: 1;
                }
            }
        }
    }
}

.PosterListRow {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.Variant--twoOnTwo,
    &.Variant--oneOnTwo {
        flex-wrap: wrap;
        margin-left: -32px;

        @media (--tablet) {
            margin-left: 0;
        }

        & .Poster {
            @mixin ImageAspectRatio 1/1;
            @mixin IgnoreChildrenFrom 5;
            display: flex;
            margin-bottom: 16px;

            @media (--tablet) {
                flex: 1;
                min-width: calc(50% - 16px);
                margin-bottom: 32px;

                &:nth-child(odd) {
                    margin-right: 32px;
                }

                &:nth-child(n + 3) {
                    margin-bottom: 0;
                }
            }

            & .PosterImageOverlay {
                width: 136px;
                align-self: flex-start;
            }

            & .PosterBody {
                flex: 1;
                margin-left: 16px;

                @media (--tablet) {
                    margin-left: 32px;
                }

                & .PosterDescription {
                    display: none;
                }
            }
        }
    }
}

.Poster {
    @mixin linkOverlay;

    & .PosterCategory {
        @mixin label;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;
    }

    & .PosterTitle {
        @mixin p;

        @media (--tablet) {
            @mixin h4;
        }
    }

    & .PosterDescription {
        @mixin p;
        margin-bottom: 40px;
    }
}

.Variant--oneOnOne {
    & .Poster {
        @mixin ImageAspectRatio 9/16;
        @mixin IgnoreChildrenFrom 2;
        width: 100%;

        & .PosterImage {
            margin: 0 -32px;
            width: calc(100% + 64px);
            max-width: calc(100% + 64px);

            @media (--tablet) {
                margin: 0;
                width: 100%;
                max-width: 100%;
            }
        }

        & .PosterBody {
            @media (--tablet) {
                margin-left: 96px;
            }

            & .PosterCategory {
                margin-top: 16px;

                @media (--tablet) {
                    margin-top: 24px;
                }
            }

            & .PosterTitle {
                @mixin h3;
                margin-bottom: 16px;
            }
        }
    }
}

.Variant--oneOnFour {
    display: block;

    @media (--tablet) {
        display: flex;
    }

    & .Poster {
        @mixin ImageAspectRatio 1/1;
        @mixin IgnoreChildrenFrom 5;

        @media (--tablet) {
            lost-column: 3/12;
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }

        & .PosterBody {
            margin-top: 24px;

            & .PosterTitle {
                @mixin h4;
                margin-bottom: 32px;
            }

            & .PosterDescription {
                display: none;
            }
        }
    }
}

.Variant--oneOnTwo {
    & .Poster {
        @media (--tablet) {
            margin-bottom: 0;
        }

        &:nth-child(n + 3) {
            display: none;
        }
    }
}

.Variant--oneLeftAndTwoOnTwoRight {
    @mixin oneLeftAndTwoOnTwoRightGridLayout;

    & .Poster {
        @mixin IgnoreChildrenFrom 6;

        &:first-child {
            @mixin ImageAspectRatio 4/3;
            margin-bottom: 32px;
            padding-bottom: 40px;

            @media (--tablet) {
                margin-bottom: 0;
            }

            & .PosterImage {
                @media (--mobileOnly) {
                    margin: 0 -32px;
                    width: calc(100% + 64px);
                    max-width: calc(100% + 64px);
                }
            }

            & .PosterCategory {
                margin-top: 24px;
            }

            & .PosterTitle {
                @mixin h3;
                margin-bottom: 16px;
            }

            & .PosterDescription {
                margin-bottom: 0;
            }
        }

        &:not(:first-child) {
            & .PosterCategory {
                margin-top: 8px;

                @media (--tablet) {
                    margin-top: 16px;
                }
            }

            & .PosterDescription {
                display: none;
            }
        }

        &:nth-child(2) {
            @mixin ImageAspectRatio 3/2;
        }

        &:nth-child(3) {
            @mixin ImageAspectRatio 4/3;
        }

        &:nth-child(4) {
            @mixin ImageAspectRatio 1/1;
        }

        &:nth-child(5) {
            @mixin ImageAspectRatio 1/1;
        }
    }
}

.Variant--oneLeftAndFourOnOneRight {
    @mixin oneLeftAndFourOnOneRightGridLayout;

    & .Poster {
        @mixin IgnoreChildrenFrom 6;

        &:first-child {
            @mixin ImageAspectRatio 9/16;
            margin-bottom: 32px;
            padding-bottom: 40px;

            @media (--tablet) {
                margin-bottom: 0;
            }

            & .PosterImage {
                @media (--mobileOnly) {
                    margin: 0 -32px;
                    width: calc(100% + 64px);
                    max-width: calc(100% + 64px);
                }
            }

            & .PosterCategory {
                margin-top: 16px;

                @media (--tablet) {
                    margin-top: 24px;
                }
            }

            & .PosterTitle {
                @mixin h3;
                margin-bottom: 16px;
            }

            & .PosterDescription {
                margin-bottom: 0;
            }
        }

        &:not(:first-child) {
            margin-bottom: 16px;

            @media (--tablet) {
                margin-bottom: 32px;
            }

            & .PosterImageOverlay,
            & .PosterDescription {
                display: none;
            }

            & .PosterTitle {
                margin-bottom: 16px;
            }
        }
    }
}
