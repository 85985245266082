.PosterListBlock {
    padding: 32px 0;

    @media (--tablet) {
        padding: 48px 0;
    }
}

.Anchor {
    position: absolute;
    transform: translateY(-48px);
    top: calc(-1 * var(--headerMobileHeight));

    @media (--tablet) {
        top: calc(-1 * var(--headerHeight));
    }
}

.Content {
    @mixin wrapper;
    position: relative;
    z-index: 1;
}

.Title {
    @mixin h2;
}

.Inner {
    margin-bottom: 32px;

    & > :not(:last-child) {
        margin-bottom: 32px;

        @media (--tablet) {
            margin-bottom: 48px;
        }
    }
}

.LoadedData {
    & header {
        display: none;
    }

    & .SingleData {
        width: 100%;
        list-style-type: none;
        margin: 0 0 32px;
        padding: 0;

        & li {
            @mixin linkOverlay;
            display: flex;
            margin-bottom: 16px;

            @media (--tablet) {
                lost-center: 8/12;
                margin-bottom: 32px;
            }

            & .Picture {
                width: 80px;
                height: 80px;
                overflow: hidden;

                & img {
                    object-fit: cover;
                }
            }

            & .LoadedDataText {
                flex: 1;
                height: 100%;
                padding: 0 0 4px 16px;

                & span {
                    @mixin label;
                    font-weight: 400;
                    margin-bottom: 8px;
                }

                & h3 {
                    @mixin p;
                    margin: 0;
                }
            }
        }
    }

    & .AllData {
        margin: 0 0 32px;
    }
}

.Variant--grey {
    background-color: var(--blackLighten60Color);
}

.Variant--white {
    background-color: var(--whiteBaseColor);
}

:global(.InfoBanner--active) {
    & .Anchor {
        top: calc((-1 * var(--headerMobileHeight)) + var(--infoBannerMobileHeight));

        @media (--tablet) {
            top: calc((-1 * var(--headerHeight)) + var(--infoBannerHeight));
        }
    }
}

:global($(hiddenHeaderClassSelector)) {
    & .Anchor {
        transform: translateY(0);
        top: 0;
    }
}
